@layer reset, base, tokens, recipes, utilities, algolia;

.modal {
  width: 100%;
}

@media (min-width: 576px) {
  .modal {
    max-width: 500px;
  }
}

@media (min-width: 768px) {
  .modal {
    max-width: 620px;
  }
}

@media (min-width: 1024px) {
  .modal {
    max-width: 700px;
  }
}

.richtext-banner a {
  border-bottom: 1px solid #000;
  color: #000;
  font-weight: bold;
  text-decoration: none;
}

.richtext-banner a:hover {
  border: 0;
}
